.all-chairities-main {
  width: 100%;
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  // max-width: 100vw;
  // max-width: 100vw;
  // max-width: 100%;
  z-index: 40;
  position: relative;
  max-height: max-content;
  font-family: "Josefin Sans", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;

  .all-nominees-list {
    width: 100%;
    -webkit-box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.1);
    .table-div {
      max-height: max-content;

      table {
        h2 {
          font-family: "JosefinSans", system-ui, -apple-system,
            BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            "Open Sans", "Helvetica Neue", sans-serif;
          font-weight: 700;
        }
        tbody {
          span.action-btn {
            font-family: "JosefinSans", system-ui, -apple-system,
              BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
              "Open Sans", "Helvetica Neue", sans-serif;
            font-weight: 700;
          }
        }
      }
    }
    input.input {
      font-family: "Josefin Sans", system-ui, -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
      font-weight: 400;
    }
  }
}

@media (min-width: 640px) {
  .all-chairities-main {
    max-width: 638px;
  }
}

@media (min-width: 768px) {
  .all-chairities-main {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .all-chairities-main {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .all-chairities-main {
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1536px) {
  .all-chairities-main {
    max-width: 1536px;
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (max-width: 768px) {
  .all-chairities-main {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;

    .all-nominees-list {
      padding-right: 10px;
      padding-left: 10px;

      .table-title {
        gap: 10px;

        span {
          font-size: 12px;
          font-family: "Josefin Sans", "system";
          font-weight: 400;
        }
      }
    }
  }
}
