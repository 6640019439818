.navbar {
  width: 100%;
  min-height: 80px;
  // max-width: 100vw;
  // max-width: 100vw;
  // max-width: 100%;
  z-index: 40;
  position: relative;
  max-height: max-content;
  -webkit-box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.1);

  .navbar-inside {
    max-width: 100%;
    margin: 0 auto;
    z-index: 10;
    position: relative;

    .navbar-main {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.25rem;
      padding-top: 1.25rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;

      img.logo {
        height: 60px;
      }

      // width: 100%;
      h1 {
        margin: 0;
        padding: 0;
        font-size: 1rem;
        background: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
        // background: linear-gradient(to right, #fff 0%, #f1fb7c 100%);

        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .profile-wallet-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        max-height: 40px;

        img.profile-icon {
          cursor: pointer;
          padding: 2px;
          border: 1px solid #5a88e1;
          margin-left: 10px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          -webkit-box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.1);
          // height: 36px;
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .navbar {
    .navbar-inside {
      .navbar-main {
        max-width: 638px;

        h1 {
          font-size: 1rem;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar {
    .navbar-inside {
      .navbar-main {
        max-width: 100%;

        h1 {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .navbar {
    .navbar-inside {
      .navbar-main {
        max-width: 1024px;

        h1 {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .navbar {
    .navbar-inside {
      .navbar-main {
        max-width: 1280px;
        padding-left: 40px;
        padding-right: 40px;

        h1 {
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media (min-width: 1536px) {
  .navbar {
    max-height: 6rem;

    .navbar-inside {
      .navbar-main {
        max-width: 1536px;
        padding-left: 128px;
        padding-right: 128px;

        h1 {
          font-size: 2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    .navbar-inside {
      .navbar-main {
        img.logo {
          height: 40px;
        }
        .profile-wallet-section {
          height: 30px;

          button.sc-fxTzYC.eZwMEI {
            font-size: 15px;
            padding: 10px;
          }
          img.profile-icon {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
