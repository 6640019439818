.all-nominees-list {
  // width: 45%;
  padding: 1.5rem;
  border-radius: 20px;
  border: 1px solid rgb(243, 244, 246);
  // background-color: #fff;
  // -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
  // -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
  // box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);

  .table-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin: 1rem 0px;
    span {
      cursor: pointer;
      padding: 0.5rem 1rem;
      background-color: #ffffff;
      border: 1px solid black;
      color: #000;
      border-radius: 9999px;
    }
    span.active {
      background-color: #000;
      border: 1px solid #000;
      color: #ffffff;
    }
  }
  input.input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 10px;
    border: 1px solid black;
    margin: 20px 0px;
  }
  .table-div {
    min-height: 50vh;
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;

    th {
      padding: 0px;
    }
  }

  img.nominee-profile {
    margin-right: 1rem;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    vertical-align: middle;
    border-spacing: 0;

    caption {
      display: none;
    }

    p,
    h2 {
      margin: 0;
    }
    h2 {
      font-size: 15px;
    }
    p {
      font-size: 12px;
    }
    thead {
      th {
        text-align: left;
        padding: 1rem;
      }
    }
    tbody {
      span.action-btn {
        cursor: pointer;
        color: #ffffff;
        font-weight: 600;
        font-size: 0.75rem;
        letter-spacing: 2px;
        line-height: 1rem;
        padding: 0.5rem 1.625rem;
        border-radius: 9999px;
        background: #dcd6fc;
        background-image: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
        background-image: -moz-linear-gradient(left, #9f8ff7 0%, #5a88e1 100%);
        background-image: -webkit-linear-gradient(
          left,
          #9f8ff7 0%,
          #5a88e1 100%
        );
      }
      .nominee-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0px;
      }

      td {
        border: solid 1px #000;
        border-style: none;
        width: max-content;
      }
      td.priority {
        text-align: center;
        font-size: x-large;
        width: 10%;
      }
      tr:nth-child(odd) {
        border-radius: 20px;
        background-color: rgb(243, 244, 246);
      }
      td:first-of-type {
        padding: 0px 10px;
      }
      td.add-this {
        text-align: center;
      }
      td:first-child {
        // border-left-style: solid;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        // border-right-style: solid;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
    svg {
      margin: 0px 5px;
      cursor: pointer;
    }
    svg.disabled {
      cursor: default;
      opacity: 0.2;
    }
  }
}

@media only screen and (max-width: 768px) {
  .all-nominees-list {
    padding: 5px;
    .table-div {
      padding: 0px;
      max-height: 60vh;
    }
    .table-title {
      span {
        padding: 5px 10px;
      }
    }
    table {
      tbody {
        span.action-btn {
          padding: 0.5rem 0.865rem;
        }
      }
    }
  }
}
