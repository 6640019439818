.choose-nominees-main {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
  // border: 1px solid black;
  .go-back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin: 0px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    max-width: max-content;
    &:hover {
      background-color: #f3f4f6;
    }
    svg {
      fill: #5a88e1;
    }
    span {
      color: #5a88e1;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
  .cn-sub-hero-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    margin: 1.5rem 0px;
    border-radius: 1.5rem;
    // background-color: #fff;
    // -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
    // -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
    // box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
    .sub-left {
      // width: 40%;
      padding: 10px;
      height: max-content;
      min-height: 200px;
      margin: 0px auto;
      // height: 200px;

      .img-background {
        margin: 0 auto;
        width: 100%;
        height: max-content;
        max-width: max-content;
        // position: relative;
        border-radius: 20px;
        background-color: #fff;
        -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);

        // max-width: 30vw;
      }
      img.nft-image {
        // position: absolute;
        // top: 0%;
        // left: 0%;
        max-width: 100%;
        border-radius: 20px;
      }
    }
    .sub-right {
      // width: 60%;
      // margin-right: 2rem;
      padding: 10px;
      // border: 1px solid black;
      span.contract-type {
        margin: 1rem;
        color: #ffffff;
        font-weight: 600;
        font-size: 0.75rem;
        letter-spacing: 2px;
        line-height: 1rem;
        padding: 0.25rem 0.625rem;
        border-radius: 9999px;
        background: #dcd6fc;
        background-image: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
        background-image: -moz-linear-gradient(left, #9f8ff7 0%, #5a88e1 100%);
        background-image: -webkit-linear-gradient(
          left,
          #9f8ff7 0%,
          #5a88e1 100%
        );
      }
      .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .nft-name {
        // padding: 0.5rem 1rem;
        p {
          font-size: 2.25rem;
          font-weight: 700;
          margin: 1.5rem 1rem;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .sub-right-name {
        background-color: rgb(243, 244, 246);
        border-radius: 5px;
        padding: 0.5rem 1rem;
        span {
          color: #111827;
          font-weight: 500;

          font-size: 15px;
        }
      }
      .sub-right-inner {
        margin: 0.5rem 0rem;
        padding: 0rem 1rem;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .sub-right-inner.details {
        display: flex;
        flex-direction: column;
        gap: 5px;
        div {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
        }
        span {
          color: #6b7280;
          font-size: 12px;
        }
        span.main-span {
          font-size: 15px;
          color: #111827;
          word-break: break-all;
        }
      }
    }
  }
  .second-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    align-items: flex-start;
    gap: 1rem;

    .all-nominees-list {
      // width: 45%;
      padding: 1.5rem;
      border-radius: 20px;
      border: 1px solid rgb(243, 244, 246);
      // background-color: #fff;
      // -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      // -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      // box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);

      .table-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        margin: 1rem 0px;
        span {
          cursor: pointer;
          padding: 0.5rem 1rem;
          background-color: #ffffff;
          border: 1px solid black;
          color: #000;
          border-radius: 9999px;
        }
        span.active {
          background-color: #000;
          border: 1px solid #000;
          color: #ffffff;
        }
      }
      input.input {
        width: 100%;
        padding: 0.5rem;
        border-radius: 10px;
        border: 1px solid black;
        margin: 20px 0px;
      }
      .table-div {
        min-height: 70vh;
        max-height: 70vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 10px;

        th {
          padding: 0px;
        }
      }
    }

    .selected-nominees-list {
      // width: 55%;
      padding: 1.5rem;
      border-radius: 20px;
      background-color: #fff;
      -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);

      .table-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        margin: 1rem 0px;
        span.active {
          border-radius: 9999px;
          background-color: #000;
          border: 1px solid #000;
          color: #ffffff;
          padding: 0.5rem 1rem;
        }
      }

      .save-btn-div {
        width: 100%;
        display: flex;
        justify-content: center;

        button {
          margin-top: 20px;
          box-shadow: none;
          border: none;
          // color: #ffffff;
          font-weight: 500;
          font-size: 1rem;
          letter-spacing: 2px;
          line-height: 1rem;
          padding: 0.5rem 1rem;
          border-radius: 9999px;
          color: #0ba360;
          background-color: #fff;
          border: 1px solid #0ba360;
          // background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
          &:hover {
            color: white;
            background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
          }
        }
      }
    }

    .nominees-not-found {
      border: 1px solid rgb(243, 244, 246);
      border-radius: 20px;
      // height: 20vh;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        max-width: 80%;

        font-size: 1rem;
        font-weight: 500;
        margin: 5px auto;
      }
    }
    img.nominee-profile {
      margin-right: 1rem;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      vertical-align: middle;
      border-spacing: 0;

      caption {
        display: none;
      }

      p,
      h2 {
        margin: 0;
      }
      h2 {
        font-size: 15px;
      }
      p {
        font-size: 12px;
      }
      thead {
        th {
          text-align: left;
          padding: 1rem;
        }
      }
      tbody {
        span.action-btn {
          cursor: pointer;
          color: #ffffff;
          font-weight: 600;
          font-size: 0.75rem;
          letter-spacing: 2px;
          line-height: 1rem;
          padding: 0.5rem 1.625rem;
          border-radius: 9999px;
          background: #dcd6fc;
          background-image: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
          background-image: -moz-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
          background-image: -webkit-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
        }
        span.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        .nominee-details {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 0px;
        }

        td {
          border: solid 1px #000;
          border-style: none;
          width: max-content;
        }
        td.priority {
          text-align: center;
          font-size: x-large;
          width: 10%;
        }
        tr:nth-child(odd) {
          border-radius: 20px;
          background-color: rgb(243, 244, 246);
        }
        td:first-of-type {
          padding: 0px 10px;
        }
        td.add-this {
          text-align: center;
        }
        td:first-child {
          // border-left-style: solid;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        td:last-child {
          // border-right-style: solid;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      svg {
        margin: 0px 5px;
        cursor: pointer;
      }
      svg.disabled {
        cursor: default;
        opacity: 0.2;
      }
    }
  }
}

@media (min-width: 640px) {
  section.choose-nominees-main {
    max-width: 640px;
  }
}

@media (min-width: 1024px) {
  section.choose-nominees-main {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  section.choose-nominees-main {
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1536px) {
  section.choose-nominees-main {
    max-width: 1536px;
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (max-width: 768px) {
  section.choose-nominees-main {
    max-width: 640px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .cn-sub-hero-section {
      gap: 1rem;
      padding: 5px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      .sub-left {
        width: 100%;
        .img-background {
          max-width: 200px;
        }
      }
      .sub-right {
        .nft-name {
          p {
            font-size: 1.5rem;
            margin: 1rem;
          }
        }
      }
    }
    .second-section {
      width: 90vw;
      margin-bottom: 50px;
      .all-nominees-list {
        max-width: calc(100vw - 5%);
        padding: 10px;

        .table-title {
          gap: 1rem;
        }
        .table-div {
          padding: 5px;
          min-height: 50vh;
          max-height: 50vh;
        }
      }
      .selected-nominees-list {
        max-width: calc(100vw - 5%);
        padding: 10px;
      }
      img.nominee-profile {
        margin-right: 10px;
      }
      table {
        h2 {
          font-size: 15px;
          max-width: 100%;
          word-break: break-all;
        }
        p {
          max-width: 100%;
          word-break: break-all;
        }
        thead {
          th {
            padding: 5px;
            font-size: 12px;
          }
        }
        tbody {
          span.action-btn {
            padding: 10px;
          }
          td.arrows {
            width: 10%;
          }
          td.priority {
            max-width: 5%;
            font-size: large;
            padding: 0px;
          }
          td.add-this {
            width: 30%;
          }
        }
      }
      .nominees-not-found {
        p {
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  section.choose-nominees-main {
    max-width: 1024px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .cn-sub-hero-section {
      gap: 1rem;
      padding: 10px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      .sub-left {
        width: 100%;
        .img-background {
          max-width: 320px;
        }
      }
      .sub-right {
        margin-right: 0px;
        padding: 5px;

        .nft-name {
          // padding: 0.5rem 1rem;
          p {
            font-size: 1.5rem;
            margin: 1rem;
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
