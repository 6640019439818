.token-nominee-main {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
  // border: 1px solid black;
  .hero-section {
    width: 100%;
    // border: 1px solid black;
    // height: 500px;
    .go-back {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      margin: 0px 5%;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      max-width: max-content;
      &:hover {
        background-color: #f3f4f6;
      }
      svg {
        fill: #5a88e1;
      }
      span {
        color: #5a88e1;
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
    .sub-hero {
      margin: 0px auto;
      margin-bottom: 40px;
      width: 90%;
      //   border: 1px solid black;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .sub-hero-left {
        width: 30%;
        min-height: 100px;
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 1rem;
        // border: 1px solid black;
        border-radius: 1.5rem;
        // border: 10px solid #9f8ff7;
        background-color: #fff;
        -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);

        span {
          font-size: 4rem;
          max-width: 100%;
          word-wrap: break-word;
          font-weight: bolder;
          background-image: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
          background-image: -moz-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
          background-image: -webkit-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );

          -webkit-background-clip: text;
          //   background-clip: text;
          color: transparent;
        }
      }
      .sub-hero-right {
        padding: 1rem 3rem;
        width: 70%;
        // border: 1px solid black;
        display: flex;
        flex-direction: column;
        gap: 10px;

        span.chain-name {
          margin-bottom: 10px;
          max-width: max-content;
          background: #dcd6fc;
          // background: linear-gradient(90deg, #dcd6fc, #9bb7ed);
          background-image: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
          background-image: -moz-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
          background-image: -webkit-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
          padding: 5px 10px;
          border-radius: 20px;
          font-size: 12px;
          color: #ffffff;
        }
        .token-details {
          display: flex;
          flex-direction: column;
          span {
            padding: 0.5rem 1rem;
          }
          span.token-sub {
            background-color: rgb(243, 244, 246);
            border-radius: 5px;
            font-size: small;
            font-weight: 500;
          }

          .token-balance {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            // gap: 0.5rem;
            p.token-balance {
              margin: 10px 0rem;
              margin-left: 1rem;
              margin-right: 10px;
              font-size: 2rem;
              font-weight: 700;
              max-width: 70%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              span {
                padding: 0px;
                font-weight: 600;
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
  .second-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    align-items: flex-start;
    gap: 1rem;

    .all-nominees-list {
      // width: 45%;
      padding: 1.5rem;
      border-radius: 20px;
      border: 1px solid rgb(243, 244, 246);
      // background-color: #fff;
      // -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      // -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      // box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);

      .table-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        margin: 1rem 0px;
        span {
          cursor: pointer;
          padding: 0.5rem 1rem;
          background-color: #ffffff;
          border: 1px solid black;
          color: #000;
          border-radius: 9999px;
        }
        span.active {
          background-color: #000;
          border: 1px solid #000;
          color: #ffffff;
        }
      }
      input.input {
        width: 100%;
        padding: 0.5rem;
        border-radius: 10px;
        border: 1px solid black;
        margin: 20px 0px;
      }
      .table-div {
        min-height: 70vh;
        max-height: 70vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 10px;
        table {
          div.nominee-details {
            border: none;
          }
          tbody {
            tr {
              background-color: none;
              border: none;
            }
            tr:nth-child(odd) {
              border-radius: 20px;
              background-color: rgb(243, 244, 246);
            }
          }
        }
        th {
          padding: 0px;
        }
      }
    }

    .selected-nominees-list {
      // width: 55%;
      width: 90%;
      margin: 0 auto;
      padding: 1.5rem;
      border-radius: 20px;
      background-color: #fff;
      -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);

      .table-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        margin: 1rem 0px;
        span.active {
          border-radius: 9999px;
          background-color: #000;
          border: 1px solid #000;
          color: #ffffff;
          padding: 0.5rem 1rem;
        }
        button.add-nominee {
          box-shadow: none;
          border: none;
          color: #ffffff;
          font-weight: 500;
          font-size: 1rem;
          letter-spacing: 2px;
          padding: 0.5rem 1rem;
          border-radius: 9999px;
          background: #dcd6fc;
          background-image: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
          background-image: -moz-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
          background-image: -webkit-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
        }
      }

      .save-btn-div {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 20px 0px;

        button {
          box-shadow: none;
          border: none;
          color: #ffffff;
          font-weight: 500;
          font-size: 1rem;
          letter-spacing: 2px;
          line-height: 1rem;
          padding: 0.5rem 1rem;
          border-radius: 9999px;
        }
        button.add-nominee {
          background: #dcd6fc;
          background-image: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
          background-image: -moz-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
          background-image: -webkit-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
        }
        button.save-nominee {
          margin-left: 20px;
          color: #0ba360;
          background-color: #fff;
          border: 1px solid #0ba360;
          // background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
          &:hover {
            color: white;
            background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
          }
        }
      }

      .nominated-ratio-main {
        border: 1px solid black;
        width: 100%;
        border-radius: 10px;
        margin: 20px 0px;
        padding-bottom: 20px;

        // border-bottom: 1px solid #000;
        .ratio-and-modify {
          padding: 10px;
          background-color: #f3f4f6;
          border-radius: 10px 10px 0px 0px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          span.ratio {
            font-size: 1.2rem;
            font-weight: 700;
            color: #5a88e1;
          }
          span {
            color: #12141e99;
          }
          .modify-delete {
            button {
              padding: 5px 10px;
              border-radius: 99999px;
            }
            button.disabled {
              pointer-events: none;
              opacity: 0.5;
            }
            button.modify {
              background-color: #ffffff;
              border: 1px solid black;
              color: black;
              margin-right: 10px;
            }
            button.delete {
              background-color: black;
              border: 1px solid black;
              color: #ffffff;
            }
          }
        }
        p.not-find-nominee {
          margin: 30px auto;
          width: 70%;
          text-align: center;
        }
        p.nominee-will-get {
          font-size: 14px;
          padding: 0px 10px;
          span {
            font-weight: 600;
          }
        }
        sup.sup-of-priority {
          font-size: 12px;
        }
        .table-div {
          // padding: 10px;
          // margin: 20px;
          // border-radius: 10px;
          // border: 1px solid #f3f4f6;
          table {
            // border: 1px solid #f3f4f6;
            width: 100%;
            margin: 20px 0px;
            thead {
              th {
                text-align: center;
                font-weight: 500;
                padding: 5px;
                font-size: 14px;
                color: #12141e99;
              }
              th:last-child {
                text-align: left;
              }
            }
            tbody {
              td.nominee-details {
                padding: 0px;
              }
              td:first-child {
                width: auto;
              }
              td:last-child {
                text-align: left;
              }
            }
            tr {
              border: 1px solid #f3f4f6;
            }
          }
          .show-more-nominees {
            margin: 0px auto;
            width: max-content;
            button {
              box-shadow: none;
              border: none;
              color: #000;
              font-weight: 500;
              font-size: 0.865rem;
              padding: 5px;
              border-radius: 5px;
              background-color: #fff;
              border: 1px solid #000;
              // background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
              &:hover {
                color: white;
                background-color: #000;
              }
            }
          }
          .add-nominee {
            margin: 20px auto;
            width: max-content;
          }
        }
      }
    }

    .nominees-not-found {
      border: 1px solid rgb(243, 244, 246);
      border-radius: 20px;
      height: 20vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        max-width: 80%;

        font-size: 1rem;
        font-weight: 500;
        margin: 5px auto;
      }
    }
    img.nominee-profile {
      margin-right: 1rem;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      vertical-align: middle;
      border-spacing: 0;

      caption {
        display: none;
      }

      p,
      h2 {
        margin: 0;
      }
      h2 {
        font-size: 15px;
      }
      p {
        font-size: 12px;
      }
      thead {
        th {
          text-align: left;
          padding: 1rem;
        }
      }
      tbody {
        td.add-this {
          span.action-btn {
            cursor: pointer;
            color: #000;
            font-weight: 600;
            font-size: 0.75rem;
            letter-spacing: 2px;
            line-height: 1rem;
            padding: 0.5rem 1.625rem;
            border-radius: 9999px;

            border: 1px solid #000;
            background: #ffffff;
            background-image: none;
          }
        }
        .nominee-details {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 0px;
          width: 100%;
        }
        div.nominated-nominee-details {
          border-radius: 10px;
          padding-left: 20px;
          padding-right: 20px;
          // border: 1px solid black;
          background-color: #f3f4f6;
          margin: 10px 0px;

          svg {
            margin-left: auto;
          }
          span.nominated-priority {
            margin-right: 10px;
            font-weight: 600;
          }
        }
        td.ratio {
          text-align: center;
        }
        td.nominee-details {
          display: flex;
          flex-direction: column;
        }
        td {
          border-style: none;

          width: max-content;
        }

        td.priority {
          text-align: center;
          font-size: x-large;
          width: 10%;
        }
        tr.selected-nominees {
          border-radius: 20px;
          border: 1px solid #f3f4f6;
        }
        td:first-of-type {
          padding: 0px 10px;
        }
        td.add-this {
          text-align: center;
        }
        td:first-child {
          // border-left-style: solid;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        td:last-child {
          // border-right-style: solid;
          text-align: center;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      svg {
        margin: 0px 5px;
        cursor: pointer;
      }
      svg.disabled {
        cursor: default;
        opacity: 0.2;
      }
    }
  }
}

@media (min-width: 640px) {
  section.token-nominee-main {
    max-width: 640px;
  }
}

@media (min-width: 1024px) {
  section.token-nominee-main {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  section.token-nominee-main {
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1536px) {
  section.token-nominee-main {
    max-width: 1536px;
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (max-width: 768px) {
  section.token-nominee-main {
    max-width: 640px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    .hero-section {
      .sub-hero {
        flex-direction: column;

        .sub-hero-left {
          width: 100%;
          margin-top: 20px;
          padding: 30px 1rem;
          span {
            font-size: 3.5rem;
          }
        }
        .sub-hero-right {
          width: 100%;
          margin-top: 20px;
          padding: 0px;
          span.chain-name {
            font-size: 10px;
          }
          .token-details {
            .token-balance {
              // flex-wrap: wrap;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
              p.token-balance {
                max-width: 100%;
                // margin-left: 0px;
                font-size: 1.5rem;
                word-break: break-all;
                white-space: inherit;
                span {
                  word-break: break-all;
                  white-space: inherit;
                }
              }
            }
            .token-address {
              word-break: break-all;
              max-width: 100%;
              // padding: 0.5rem 0px;
            }
          }
        }
      }
    }
    .second-section {
      display: block;
      width: 100%;
      margin-bottom: 50px;
      .all-nominees-list {
        max-width: calc(100vw - 2%);
        padding: 10px;

        .table-title {
          gap: 1rem;
        }
        .table-div {
          padding: 5px;
          min-height: 50vh;
          max-height: 50vh;
        }
      }
      .selected-nominees-list {
        max-width: calc(100vw - 5%);
        padding: 10px;
        .save-btn-div {
          button {
            font-size: 0.865rem;
            padding: 10px 20px;
          }
          button.save-nominee {
            margin-left: 10px;
          }
        }
      }
      img.nominee-profile {
        margin-right: 10px;
      }
      table {
        h2 {
          font-size: 15px;
          max-width: 100%;
          word-break: break-all;
        }
        p {
          max-width: 100%;
          word-break: break-all;
        }
        thead {
          th {
            padding: 5px;
            font-size: 12px;
          }
        }
        tbody {
          span.action-btn {
            padding: 10px;
          }
          td.arrows {
            width: 10%;
          }
          td.priority {
            max-width: 5%;
            font-size: large;
            padding: 0px;
          }
          td.add-this {
            width: 30%;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  section.token-nominee-main {
    max-width: 1024px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
