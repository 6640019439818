.update-view-main {
  div.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    padding: 0.5rem 0px;
    span {
      background-color: #000;
      color: #ffffff;
      border-radius: 99999px;
      padding: 0.5rem 1rem;
      // margin-bottom: 10px;
    }
  }
  .details {
    margin: 10px 10px;
    span.title {
      font-size: 15px;
    }
    span.content {
      font-size: 1rem;
      font-weight: 700;
    }
  }
  .table-div {
    min-height: 50vh;
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;

    table {
      margin: 0 auto;
      width: 90%;
      max-width: 800px;
      border-collapse: collapse;
      text-align: left;
      vertical-align: middle;
      border-spacing: 0;
      border: 1px solid #f3f4f6;

      caption {
        display: none;
      }
      tr {
        border: 1px solid #f3f4f6;
      }
      p,
      h2 {
        margin: 0;
      }
      h2 {
        font-size: 15px;
      }
      p {
        font-size: 12px;
      }
      thead {
        th {
          text-align: left;
          padding: 1rem;
        }
      }
      tbody {
        td.add-this {
          span.action-btn {
            cursor: pointer;
            color: #000;
            font-weight: 600;
            font-size: 0.75rem;
            letter-spacing: 2px;
            line-height: 1rem;
            padding: 0.5rem 1.625rem;
            border-radius: 9999px;

            border: 1px solid #000;
            background: #ffffff;
            background-image: none;
          }
        }
        .nominee-details {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 5px 0px;
          width: 100%;
          img {
            margin-right: 1rem;
            width: 40px;
            height: 40px;
            border-radius: 20px;
          }
        }
        div.nominated-nominee-details {
          border-radius: 10px;
          padding-left: 20px;
          padding-right: 20px;
          // border: 1px solid black;
          background-color: #f3f4f6;
          margin: 10px 0px;

          svg {
            margin-left: auto;
          }
          span.nominated-priority {
            margin-right: 10px;
            font-weight: 600;
          }
        }
        td.ratio {
          text-align: center;
        }
        td.nominee-details {
          display: flex;
          flex-direction: column;
        }
        td {
          border-style: none;

          width: max-content;
        }

        td.priority {
          text-align: center;
          font-size: large;
          font-weight: 600;
          width: 10%;
          sup.sup-of-priority {
            font-size: 12px;
          }
        }
        tr.selected-nominees {
          border-radius: 20px;
          border: 1px solid #f3f4f6;
        }
        td:first-of-type {
          padding: 0px;
        }
        td.add-this {
          text-align: center;
        }
        td:first-child {
          // border-left-style: solid;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        td:last-child {
          // border-right-style: solid;
          text-align: center;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      svg {
        margin: 0px 5px;
        cursor: pointer;
      }
      svg.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.2;
      }
    }
    th {
      padding: 5px;
      font-size: 12px;
      color: #12141e99;
    }
  }
  .save-btn-div {
    width: 90%;
    margin: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;

    button {
      margin-top: 20px;
      box-shadow: none;
      border: none;
      color: #ffffff;
      font-weight: 500;
      font-size: 0.8rem;
      letter-spacing: 2px;
      line-height: 1rem;
      padding: 10px 1.5rem;
      border-radius: 9999px;
    }
    button.add-nominee {
      background: #dcd6fc;
      background-image: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
      background-image: -moz-linear-gradient(left, #9f8ff7 0%, #5a88e1 100%);
      background-image: -webkit-linear-gradient(left, #9f8ff7 0%, #5a88e1 100%);
    }
    button.save-nominee {
      color: #0ba360;
      background-color: #fff;
      border: 1px solid #0ba360;
      // background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
      &:hover {
        color: white;
        background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .update-view-main {
    .table-div {
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            padding: 5px;
          }
        }
      }
    }
  }
}
