.select-nominee-main {
  position: fixed;
  top: 0;
  right: 0;

  width: 100%;
  background-color: #00000090;
  z-index: 100;
  height: 100vh;

  .select-nominee-inside {
    margin: 10vh auto;
    max-width: 1200px;
    max-height: 80vh;

    .nominees-main {
      height: 80vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;

      .add-nominee {
        background-color: #ffffff;
        position: sticky;
        top: 0;
        height: 10%;
        margin: 0 auto;
      }
    }
  }
}

.nominees-main {
  background-color: #fff;
  margin-top: 3rem;
  width: 100%;
  border-radius: 1.5rem;
  padding: 20px 0px;
  -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);

  .add-nominee {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    max-width: 1024px;
    margin: 20px auto;

    button.add-nominee-btn {
      font-family: "Josefin Sans";
      font-weight: 400;
      cursor: pointer;
      border-radius: 9999px;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.25rem;
      padding: 0.5rem 1rem;
      border: none;
      background-color: #111827;
      color: #f9fafb;
      margin: 0 10px;
    }
  }

  .nominees-container {
    cursor: pointer;
    max-width: 1024px;
    margin: 10px auto;
    padding: 30px 30px;
    // border: 1px solid #000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #f3f4f6;
    transition: box-shadow 0.25s ease-in-out;

    &:hover {
      border: none;
      border-radius: 1.5rem;
      -webkit-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 10px 25px 10px rgba(0, 0, 0, 0.1);

      .nominees-details {
        h2 {
          color: #5a88e1;
        }
      }

      .nominees-last {
        button {
          color: #ffffff;
          border: 1px solid #dcd6fc;
          background: #dcd6fc;
          background-image: linear-gradient(to right, #9f8ff7 0%, #5a88e1 100%);
          background-image: -moz-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
          background-image: -webkit-linear-gradient(
            left,
            #9f8ff7 0%,
            #5a88e1 100%
          );
        }
      }
    }

    &:last-of-type {
      border: none;
    }

    .nominees-profile {
      margin: 0;
      margin-right: 30px;

      img {
        height: 64px;
        width: 64px;
        border-radius: 9999px;
      }
    }

    .nominees-details {
      margin-left: 10px;

      h2,
      p {
        font-family: "Josefin Sans";
        font-weight: 400;
        margin: 0 0 5px;
        padding: 0;
        transition: color 0.25s ease-in-out;
      }

      h2 {
        font-family: "JosefinSans";
        font-weight: 700;
        color: #5a88e1;
      }
    }

    .nominees-last {
      margin-left: auto;

      button {
        align-self: center;
        // cursor: pointer;
        margin: 10px 0px 20px;
        padding: 0.5rem 1rem;
        border-radius: 9999px;
        font-family: "Josefin Sans";
        font-weight: 400;
        // color: white;

        // background-image: none;
        // background-color: black;
        // color: white;
        // border: 1px solid black;
        transition: background-color 0.25s ease-in-out;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .nominees-main {
    .nominees-container {
      padding: 10px;
      .nominees-profile {
        margin-right: 0px;
        img {
          height: 32px;
          width: 32px;
        }
      }
      .nominees-details {
        h2 {
          font-size: 1.2rem;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
}
